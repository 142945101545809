import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { sortBy, omit, last, first } from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

import Layout from '../components/layout';
import Map from '../components/graphics/map';
import Summary from '../components/graphics/summary';
import DotChart from '../components/graphics/dot';
import SectionButtons from '../components/page/sectionButtons';
import IndicatorMenu from '../components/page/indicatorMenu';
import { colorSchemes, unclassedChoropleth, extractObject } from '../components/util/choropleth';
import { rankings } from '../components/util/ordinal';

export const query = graphql`
  query indicatorPage($id: ID!) {
    hope {
      Indicator(where: { id: $id }) {
        name
        domain {
          color
        }
        definition
        dtg
        dtgtxt
        footnote
        goal
        goaltxt
        key
        natlrate
        natltxt
        format
        summary
        whymatter
        values {
          state {
            abbreviation
          }
          score: isscat
          labels: iss
          aian: aian_rate
          aspi: aspi_rate
          black: black_rate
          hisp: hisp_rate
          multi: multi_rate
          white: white_rate
          dtgquin
          eqquin
        }
      }
    }
  }
`;

export default function Indicator({ data }) {
  const {
    hope: {
      Indicator: {
        name,
        whymatter,
        definition,
        footnote,
        domain: { color },
        goal,
        format,
        summary,
        values,
      },
    },
  } = data;

  const classification = unclassedChoropleth(values, color, 1, 9);
  const race = sortBy(values, v => v.state.abbreviation).map(r =>
    omit(r, ['score', 'labels', 'dtgquin', 'eqquin'])
  );

  const probeValues = {};
  values.forEach(v => {
    probeValues[v.state.abbreviation] = [
      { label: 'Inequity', value: rankings.inequity[v.eqquin] },
      { label: 'Distance to Goal', value: rankings.dtg[v.dtgquin] },
    ];
  });

  return (
    <Layout>
      <Container>
        <Row noGutters>
          <Col>
            <h1>HOPE Data by Indicator</h1>
            <SectionButtons />
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={{ order: 'last' }} style={{ marginTop: -10 }}>
            <IndicatorMenu
              handler={indicator =>
                navigate(`/indicator/${indicator.name.replace(/(\W+)/gm, '-').toLowerCase()}`)
              }
            />
          </Col>
          <Col lg={4} sm={{ order: 'first' }}>
            <h3 style={{ color, marginTop: 0 }}>{name}</h3>
          </Col>
        </Row>
        <Row noGutters>
          <Col md={7}>
            <p>{definition}</p>
          </Col>
        </Row>
        <Map
          title="Indicator Summary Score"
          summary="Numbers listed on the maps are indicator summary scores, which measure how far a state has to go to meet the HOPE Goal (Distance to Goal) and how much variation there is across racial and ethnic groups within the state on the measure (Racial Inequity). Scores range from 0 to 100 with 100 indicating the state with the best combined performance. Hovering over a state reveals information on Distance to Goal and Racial Inequity separately."
          values={extractObject(classification, 'labels')}
          colors={extractObject(classification, 'color')}
          scheme={[
            last(colorSchemes[color.toLowerCase()]),
            first(colorSchemes[color.toLowerCase()]),
          ]}
          legendValues={[0, 100]}
          probeValues={probeValues}
        />
        <hr />
        <Summary data={data.hope.Indicator} numberFormat={format} />
        <hr />
        <Row noGutters>
          <Col lg={4}>
            <h3 style={{ marginTop: 0 }}>Why it Matters</h3>
            <p>{whymatter}</p>
          </Col>
          <Col lg={8}>
            <h3 style={{ marginTop: 0, marginLeft: 20 }}>Indicator Summary</h3>
            {parse(summary)}
            {footnote && (
              <div className="footnote" style={{ marginLeft: 20 }}>{`* ${parse(footnote)}`}</div>
            )}
          </Col>
        </Row>
        <hr />
      </Container>
      <Container>
        <DotChart
          values={race}
          goal={goal.match(/^\d+\.?\d*$/) ? parseFloat(goal) : null}
          title={name}
          numberFormat={format}
        />
      </Container>
    </Layout>
  );
}

Indicator.propTypes = {
  data: PropTypes.shape({
    hope: PropTypes.shape(),
  }).isRequired,
};
